body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #001628;
}

.App {
  text-align: center;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  z-index: 1;
}

h1 {
  color: white;
  font-size: 4.5em;
  font-weight: bold;
  margin-top: 1px;
  text-shadow: 2px 2px 2px orange; 
}

.bitcoinproofer-title, .bitcoinproofer-title:visited, .bitcoinproofer-title:hover, .bitcoinproofer-title:active {
  text-decoration: none;
  color: inherit;
}

p {
  color: white;
  font-size: 1.5em;
  margin-top: 1px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-right: 100px;
  margin-bottom: 50px;
}

.custom-image {
  width: 500px;
  height: 500px;
  margin-right: 20px;
}


.input-container {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.input-field {
  padding: 15px;
  width: 400px;
  margin-right: 10px;
  font-size: 1em;
  border: 2px solid #ccc;
  border-radius: 25px;
  outline: none;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border-color: #ff9800;
}

.submit-button {
  padding: 15px 30px;
  font-size: 1em;
  color: white;
  border: 2px solid orange;
  border-radius: 25px;
  background-color: orange;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  color: green;
  border-color: green;
}

.how-it-works-link {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-decoration: underline;
  font-size: 1em;
}
.how-it-works-link:hover {
  text-decoration: underline;
}

.how-it-works-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: auto;
}

.how-it-works-container {
  padding: 10px;
  margin-bottom: 100px;
}

.orange-text {
  color: orange;
}

.input-field-error {
  border-color: red !important;
}


.verified-data-message {
  width: 510px;
  height: 200px;
  background-color: rgba(255, 165, 0, 0.95);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 210px auto 0;
  text-align: center;
}



/* Responsive adjustments */
@media (max-width: 768px) {
  .bitcoinproofer-title h1 {
    font-size: 2.5rem; /* Smaller font size on smaller screens */
  }

  .input-container {
    padding: 0 15px; 
    flex-direction: column;
  }

  .submit-button {
    padding: 10px 20px;
  }

  .input-field {
    width: 250px;
  }

  .verified-data-message {
    width: 250px;
    font-size: 0.8em;
  }
}